<template>
  <div class="row">
    <div class="col-12">
      <Breadcrumb :items="breadcrumbs" />
      <div class="lk">
        <div class="row">
          <div class="col-lg-3">
            <div class="lk-nav">
              <UserBar />
              <SideBar />
            </div>
          </div>
          <div class="col-lg-9">
            <div class="helpers">
              <div class="row">
                <div class="col-lg-3 col-md-4 col-6 col-sm-4 helpers__item">
                  <div class="helper-card">
                    <div class="helper-card__header">
                      <div class="helper-card__img">
                        <img src="@/assets/img/helper-ava.jpg" alt="">
                      </div>
                      <div class="helper-card__info">
                        <div class="helper-card__post">Менеджер</div>
                        <div class="helper-card__name">Инна Бондаренко</div>
                      </div>
                    </div>
                    <a href="tel:38 (057) 763 6096" class="helper-card__phone">
                      38 (057) 763 6096
                    </a>
                    <div class="helper-card__email">
                      bondarinna@gmail.com
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-sm-4 helpers__item">
                  <div class="helper-card">
                    <div class="helper-card__header">
                      <div class="helper-card__img">
                        <img src="@/assets/img/helper-ava.jpg" alt="">
                      </div>
                      <div class="helper-card__info">
                        <div class="helper-card__post">Менеджер</div>
                        <div class="helper-card__name">Инна Бондаренко</div>
                      </div>
                    </div>
                    <a href="tel:38 (057) 763 6096" class="helper-card__phone">
                      38 (057) 763 6096
                    </a>
                    <div class="helper-card__email">
                      bondarinna@gmail.com
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-sm-4 helpers__item">
                  <div class="helper-card">
                    <div class="helper-card__header">
                      <div class="helper-card__img">
                        <img src="@/assets/img/helper-ava.jpg" alt="">
                      </div>
                      <div class="helper-card__info">
                        <div class="helper-card__post">Менеджер</div>
                        <div class="helper-card__name">Инна Бондаренко</div>
                      </div>
                    </div>
                    <a href="tel:38 (057) 763 6096" class="helper-card__phone">
                      38 (057) 763 6096
                    </a>
                    <div class="helper-card__email">
                      bondarinna@gmail.com
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-sm-4 helpers__item">
                  <div class="helper-card">
                    <div class="helper-card__header">
                      <div class="helper-card__img">
                        <img src="@/assets/img/helper-ava.jpg" alt="">
                      </div>
                      <div class="helper-card__info">
                        <div class="helper-card__post">Менеджер</div>
                        <div class="helper-card__name">Инна Бондаренко</div>
                      </div>
                    </div>
                    <a href="tel:38 (057) 763 6096" class="helper-card__phone">
                      38 (057) 763 6096
                    </a>
                    <div class="helper-card__email">
                      bondarinna@gmail.com
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-sm-4 helpers__item">
                  <div class="helper-card">
                    <div class="helper-card__header">
                      <div class="helper-card__img">
                        <img src="@/assets/img/helper-ava.jpg" alt="">
                      </div>
                      <div class="helper-card__info">
                        <div class="helper-card__post">Менеджер</div>
                        <div class="helper-card__name">Инна Бондаренко</div>
                      </div>
                    </div>
                    <a href="tel:38 (057) 763 6096" class="helper-card__phone">
                      38 (057) 763 6096
                    </a>
                    <div class="helper-card__email">
                      bondarinna@gmail.com
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-sm-4 helpers__item">
                  <div class="helper-card">
                    <div class="helper-card__header">
                      <div class="helper-card__img">
                        <img src="@/assets/img/helper-ava.jpg" alt="">
                      </div>
                      <div class="helper-card__info">
                        <div class="helper-card__post">Менеджер</div>
                        <div class="helper-card__name">Инна Бондаренко</div>
                      </div>
                    </div>
                    <a href="tel:38 (057) 763 6096" class="helper-card__phone">
                      38 (057) 763 6096
                    </a>
                    <div class="helper-card__email">
                      bondarinna@gmail.com
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-sm-4 helpers__item">
                  <div class="helper-card">
                    <div class="helper-card__header">
                      <div class="helper-card__img">
                        <img src="@/assets/img/helper-ava.jpg" alt="">
                      </div>
                      <div class="helper-card__info">
                        <div class="helper-card__post">Менеджер</div>
                        <div class="helper-card__name">Инна Бондаренко</div>
                      </div>
                    </div>
                    <a href="tel:38 (057) 763 6096" class="helper-card__phone">
                      38 (057) 763 6096
                    </a>
                    <div class="helper-card__email">
                      bondarinna@gmail.com
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-sm-4 helpers__item">
                  <div class="helper-card">
                    <div class="helper-card__header">
                      <div class="helper-card__img">
                        <img src="@/assets/img/helper-ava.jpg" alt="">
                      </div>
                      <div class="helper-card__info">
                        <div class="helper-card__post">Менеджер</div>
                        <div class="helper-card__name">Инна Бондаренко</div>
                      </div>
                    </div>
                    <a href="tel:38 (057) 763 6096" class="helper-card__phone">
                      38 (057) 763 6096
                    </a>
                    <div class="helper-card__email">
                      bondarinna@gmail.com
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-sm-4 helpers__item">
                  <div class="helper-card">
                    <div class="helper-card__header">
                      <div class="helper-card__img">
                        <img src="@/assets/img/helper-ava.jpg" alt="">
                      </div>
                      <div class="helper-card__info">
                        <div class="helper-card__post">Менеджер</div>
                        <div class="helper-card__name">Инна Бондаренко</div>
                      </div>
                    </div>
                    <a href="tel:38 (057) 763 6096" class="helper-card__phone">
                      38 (057) 763 6096
                    </a>
                    <div class="helper-card__email">
                      bondarinna@gmail.com
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-sm-4 helpers__item">
                  <div class="helper-card">
                    <div class="helper-card__header">
                      <div class="helper-card__img">
                        <img src="@/assets/img/helper-ava.jpg" alt="">
                      </div>
                      <div class="helper-card__info">
                        <div class="helper-card__post">Менеджер</div>
                        <div class="helper-card__name">Инна Бондаренко</div>
                      </div>
                    </div>
                    <a href="tel:38 (057) 763 6096" class="helper-card__phone">
                      38 (057) 763 6096
                    </a>
                    <div class="helper-card__email">
                      bondarinna@gmail.com
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-sm-4 helpers__item">
                  <div class="helper-card">
                    <div class="helper-card__header">
                      <div class="helper-card__img">
                        <img src="@/assets/img/helper-ava.jpg" alt="">
                      </div>
                      <div class="helper-card__info">
                        <div class="helper-card__post">Менеджер</div>
                        <div class="helper-card__name">Инна Бондаренко</div>
                      </div>
                    </div>
                    <a href="tel:38 (057) 763 6096" class="helper-card__phone">
                      38 (057) 763 6096
                    </a>
                    <div class="helper-card__email">
                      bondarinna@gmail.com
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-6 col-sm-4 helpers__item">
                  <div class="helper-card">
                    <div class="helper-card__header">
                      <div class="helper-card__img">
                        <img src="@/assets/img/helper-ava.jpg" alt="">
                      </div>
                      <div class="helper-card__info">
                        <div class="helper-card__post">Менеджер</div>
                        <div class="helper-card__name">Инна Бондаренко</div>
                      </div>
                    </div>
                    <a href="tel:38 (057) 763 6096" class="helper-card__phone">
                      38 (057) 763 6096
                    </a>
                    <div class="helper-card__email">
                      bondarinna@gmail.com
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Callback />
</template>
<script>
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import Callback from '~/pages/account/partials/callback'
import UserBar from '~/pages/account/partials/userBar'
import SideBar from '~/pages/account/partials/sidebar'
export default {
  components: {
    Callback,
    UserBar,
    SideBar
  },
  setup () {
    const { t } = useI18n()
    useMeta({
      title: t('help')
    })
    return {
      t,
      breadcrumbs: [
        { name: t('home'), href: '/' },
        { name: t('help'), href: null, active: true }
      ]
    }
  }
}
</script>
